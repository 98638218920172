import Vue from 'vue'
import Vuex from 'vuex'
import { accesibilidad } from 'sisdai-componentes/src/stores'

Vue.use(Vuex)
const navegacion ={
  state:{
    cdn_archivos_dominio: process.env.VUE_APP_CDN_ARCHIVOS,
  },
  getters:{
    cdnArchivos(state) {
      // Aquí puedes completar la ruta para el cdn. El dominio ddepende del ambiente, 
      // pero la ruta a los archivos deberá coincidir en ambos dominios
      return `${state.cdn_archivos_dominio}`;
    },
  }
}
export default new Vuex.Store({
  modules: {
    accesibilidad,
    navegacion
  }
})
