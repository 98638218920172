var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_setup.store.getters['accesibilidad/clasesAccesibles'],attrs:{"id":"app"}},[_c('a',{staticClass:"ir-contenido-principal",attrs:{"href":"#principal"}},[_vm._v("Ir a contenido principal")]),_c('SisdaiNavegacionGobMx'),_c('SisdaiNavegacionPrincipal',{attrs:{"navInformacion":`Sección: <b>${_vm.$route.name}</b>`}},[_c('ul',{staticClass:"nav-menu"},[_c('li',[_c('router-link',{staticClass:"nav-hipervinculo",attrs:{"to":"/","exact":""}},[_vm._v("Inicio")])],1),_c('li',[_c('router-link',{staticClass:"nav-hipervinculo",attrs:{"to":"/disenio/"}},[_vm._v("Diseño")])],1),_c('li',[_c('router-link',{staticClass:"nav-hipervinculo",attrs:{"to":"/accesibilidad/"}},[_vm._v("Accesibilidad")])],1),_c('li',[_c('router-link',{staticClass:"nav-hipervinculo",attrs:{"to":"/desarrollo/"}},[_vm._v("Desarrollo")])],1),_c('li',[_c('router-link',{staticClass:"nav-hipervinculo",attrs:{"to":"/acceso-abierto"}},[_vm._v("Acceso Abierto")])],1),_c('li',[_c('router-link',{staticClass:"nav-hipervinculo",attrs:{"to":"/acerca-de"}},[_vm._v("Acerca de")])],1)])]),_c('router-view',{attrs:{"id":"contenido-todo"}}),_c('SisdaiMenuAccesibilidad',{ref:"sisdaiMenuAccesibilidad",attrs:{"objetoStore":_setup.store}}),_c('SisdaiBotonFlotante',{attrs:{"enlaces":[
      {
        clasesCss: 'eni',
        contenido: 'Ir a ENI',
        enlace: 'https://eni.conahcyt.mx/',
      },
      {
        clasesCss: 'gema',
        contenido: 'Ir a GEMA',
        enlace: 'https://gema.conahcyt.mx/',
      },
    ]}}),_c('SisdaiInfoDeDespliegue',{attrs:{"entornoProyecto":_setup.infoDespliegue.entornoProyecto,"versionProyecto":_setup.infoDespliegue.versionProyecto,"actualizacionProyecto":_setup.infoDespliegue.actualizacionProyecto}}),_c('SisdaiPiePaginaConahcyt'),_c('SisdaiPiePaginaGobMx')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }