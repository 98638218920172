<template>
  <div>
    <main id="principal" class="inicio">
      <div class="contenedor-portada">
        <video
          id="v-escritorio"
          class="portada"
          autoplay
          muted
          loop
          playsinline
        >
          <source
            :src="`${cdnArchivos}/inicio/videos/sisdaiescritorio.mp4`"
            type="video/mp4"
          />
        </video>
        <video id="v-movil" class="portada" autoplay muted loop playsinline>
          <source
            :src="`${cdnArchivos}/inicio/videos/sisdaimovil.mp4`"
            type="video/mp4"
          />
        </video>
        <div class="degradado-negro-abajo">
          <div class="contenido-abajo">
            <div class="contenedor ancho-fijo">
              <h1 class="titulo-eni texto-centrado texto-color-1">
                Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
              </h1>
            </div>
          </div>
        </div>
      </div>
      <section id="que-es" class="m-y-10">
        <div class="contenedor ancho-lectura texto-centrado">
          <p class="m-b-7">
            Este sistema potencia el impacto social de proyectos de
            investigación al promover la autonomía e independencia tecnológica
            con el uso de componentes de software libre. Contenido abierto,
            reutilizable y modular para la elaboración de productos digitales,
            coherentes, consistentes y comprensibles.
          </p>
        </div>
        <div class="contenedor ancho-fijo">
          <div class="flex flex-contenido-separado">
            <div class="columna-8 m-y-1">
              <div class="tarjeta">
                <div class="contenido-html">
                  <div>
                    <h2>Diseña interfaces accesibles</h2>
                    <p class="p-b-3">
                      ¿Eres una persona diseñadora?, en Sisdai encontrarás un
                      conjunto de patrones y prácticas con las que podrás
                      elaborar tu propio proyecto de investigación, te ayudará a
                      darle visibilidad a la información y a generar una
                      interfaz accesible.
                    </p>
                  </div>
                  <div>
                    <router-link class="boton boton-primario" to="/disenio/">
                      Ir a sección Diseño
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="columna-8 m-y-1">
              <div class="tarjeta">
                <div class="contenido-html">
                  <div>
                    <h2>
                      Desarrolla un portal para tu proyecto de investigación
                    </h2>
                    <p class="p-b-3">
                      ¿Eres una persona desarrolladora?, utiliza los
                      componentes, conoce las bibliotecas y colabora en los
                      repositorios Git para elaborar proyectos de investigación.
                    </p>
                  </div>
                  <div>
                    <router-link class="boton boton-primario" to="/desarrollo/">
                      Ir a sección Desarrollo
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="que-se-puede-hacer" class="m-y-10">
        <div class="contenedor ancho-fijo">
          <h2 class="texto-centrado">¿Qué puedes hacer en Sisdai?</h2>

          <div class="flex flex-contenido-separado m-y-5">
            <div class="columna-8 flex">
              <h3 class="color-texto-landing-sisdai">
                Diseñar un portal accesible
              </h3>
              <picture>
                <source
                  :srcset="`${cdnArchivos}/inicio/accesibilidad-landing.webp`"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  class="oculta-escritorio"
                  :src="`${cdnArchivos}/inicio/accesibilidad-landing.png`"
                  alt=""
                />
              </picture>
              <p>
                El Sisdai cumple con varios de los
                <a
                  href="https://www.w3.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >estándares de la W3C
                  <span class="icono-enlace-externo"></span>
                </a>
                sobre HTML y CSS. También cuenta con diversas funciones
                accesibles para que el mayor número de personas puedan
                navegarlo. Te recomendamos explorar nuestra
                <router-link to="/accesibilidad/">
                  Declaración de Accesibilidad </router-link
                >.
              </p>
              <p></p>
            </div>
            <div class="columna-8 flex">
              <picture>
                <source
                  :srcset="`${cdnArchivos}/inicio/accesibilidad-landing.webp`"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  class="oculta-movil"
                  :src="`${cdnArchivos}/inicio/accesibilidad-landing.png`"
                  alt=""
                />
              </picture>
            </div>
          </div>
          <div class="flex flex-contenido-separado m-y-5">
            <div class="columna-8 flex">
              <h3 class="color-texto-landing-sisdai">
                Colaborar con componentes libres que privilegian el idioma español
              </h3>
              <picture>
                <source
                  :srcset="`${cdnArchivos}/inicio/espaniol.webp`"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  class="oculta-escritorio"
                  :src="`${cdnArchivos}/inicio/espaniol.png`"
                  alt=""
                />
              </picture>
              <p>
                Para los equipos de desarrollo y análisis se tendrá acceso a
                bibliotecas de componentes de código abierto, replicable y con
                licencias abiertas donde se podrá colaborar para mejorar en
                comunidad, todo en tu idioma y con alcance regional.
              </p>
            </div>
            <div class="columna-8 flex">
              <picture>
                <source
                  :srcset="`${cdnArchivos}/inicio/espaniol.webp`"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  class="oculta-movil"
                  :src="`${cdnArchivos}/inicio/espaniol.png`"
                  alt=""
                />
              </picture>
            </div>
          </div>
          <div class="flex flex-contenido-separado m-y-5">
            <div class="columna-8 flex">
              <h3 class="color-texto-landing-sisdai">
                Generar visualizaciones de datos
              </h3>
              <picture>
                <source
                  :srcset="`${cdnArchivos}/inicio/visualizaciones.webp`"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  class="oculta-escritorio"
                  :src="`${cdnArchivos}/inicio/visualizaciones.png`"
                  alt=""
                />
              </picture>
              <p>
                La visualización de datos es una gran herramienta para
                sintetizar información. Desde barras, donas y líneas hasta
                tableros de información, explora cuál es la más apropiada para
                tu proyecto.
              </p>
            </div>
            <div class="columna-8 flex">
              <picture>
                <source
                  :srcset="`${cdnArchivos}/inicio/visualizaciones.webp`"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  class="oculta-movil"
                  :src="`${cdnArchivos}/inicio/visualizaciones.png`"
                  alt=""
                />
              </picture>
            </div>
          </div>
          <div class="flex flex-contenido-separado m-y-5">
            <div class="columna-8 flex">
              <h3 class="color-texto-landing-sisdai">
                Desarrollar capítulos de Ecosistemas Nacionales Informáticos
                (ENI)
              </h3>
              <picture>
                <source
                  :srcset="`${cdnArchivos}/inicio/creacion.webp`"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  class="oculta-escritorio"
                  :src="`${cdnArchivos}/inicio/creacion.png`"
                  alt=""
                />
              </picture>
              <p>
                Si tu equipo de investigación quiere desarrollar el capítulo de
                un ENI usa el Sisdai para conocer las herramientas gráficas y de
                desarrollo. Los ENI son espacios colaborativos y de acceso
                abierto que contribuyen al conocimiento local y regional para
                atender los problemas prioritarios de México al alinearse a las
                temáticas de los
                <a
                  href="https://conahcyt.mx/pronaces/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Pronaces <span class="icono-enlace-externo"></span>
                </a>
              </p>
            </div>
            <div class="columna-8 flex">
              <picture>
                <source
                  :srcset="`${cdnArchivos}/inicio/creacion.webp`"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  class="oculta-movil"
                  :src="`${cdnArchivos}/inicio/creacion.png`"
                  alt=""
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section id="aplicaciones" class="m-t-10 m-b-3">
        <div class="contenedor ancho-lectura">
          <h2 class="texto-centrado">
            Componentes Sisdai que puedes ver aplicados
          </h2>
        </div>
        <div class="contenedor m-t-8 m-b-7">
          <div class="flex flex-contenido-separado">
            <div class="columna-4 columna-8-mov">
              <div class="tarjeta">
                <picture class="fondo-imagen">
                  <source
                    :srcset="`${cdnArchivos}/inicio/VIH.webp`"
                    type="image/webp"
                  />
                  <img
                    loading="lazy"
                    class="fondo-imagen"
                    :src="`${cdnArchivos}/inicio/VIH.png`"
                    alt=""
                  />
                </picture>
                <div class="contenido-html con-imagen">
                  <div>
                    <p class="notas">ENI Salud</p>
                    <p class="parrafo-texto-desplazado">Sección estadísticas</p>
                    <p>
                      Visualizaciones de datos del capítulo
                      <i>
                        Red de transmisión y vigilancia de farmacorresistencia
                        del VIH en la CDMX
                      </i>
                    </p>
                  </div>
                  <div>
                    <a
                      href="https://salud.conahcyt.mx/vih/estadisticas"
                      target="_blank"
                      aria-label="Ver componente en estadísticas de VIH. Abre nueva pestaña."
                      class="boton boton-primario boton-chico m-t-3"
                    >
                      Ver componente
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="columna-4 columna-8-mov">
              <div class="tarjeta">
                <picture class="fondo-imagen">
                  <source
                    :srcset="`${cdnArchivos}/inicio/Rescatelago.webp`"
                    type="image/webp"
                  />
                  <img
                    loading="lazy"
                    class="fondo-imagen"
                    :src="`${cdnArchivos}/inicio/Rescatelago.png`"
                    alt=""
                  />
                </picture>
                <div class="contenido-html con-imagen">
                  <div>
                    <p class="notas">ENI Sistemas Socioecológicos</p>
                    <p class="parrafo-texto-desplazado">
                      Rescate del Lago de Texcoco, diagnóstico, contexto
                      socio-territorial
                    </p>
                    <p>
                      Mapa interactivo del capítulo
                      <i>Rescate del Lago de Texcoco.</i>
                    </p>
                  </div>
                  <div>
                    <a
                      href="https://socioecologia.conahcyt.mx/texcoco/diagnostico"
                      target="_blank"
                      aria-label="Ver componente en mapa interactivo del capítulo Rescate del Lago de Texcoco. Abre nueva pestaña."
                      class="boton boton-primario boton-chico m-t-3"
                    >
                      Ver componente
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="columna-4 columna-8-mov">
              <div class="tarjeta">
                <picture class="fondo-imagen">
                  <source
                    :srcset="`${cdnArchivos}/inicio/Sargazo.webp`"
                    type="image/webp"
                  />
                  <img
                    loading="lazy"
                    class="fondo-imagen"
                    :src="`${cdnArchivos}/inicio/Sargazo.png`"
                    alt=""
                  />
                </picture>
                <div class="contenido-html con-imagen">
                  <div>
                    <p class="notas">ENI Energía y cambio climático</p>
                    <p class="parrafo-texto-desplazado">
                      Comunidad involucrada en adaptación y mitigación de
                      sargazo en México
                    </p>
                    <p>
                      Mapa interactivo del capítulo
                      <i>
                        Arribo del sargazo pelágico a las costas mexicanas
                      </i>
                    </p>
                  </div>
                  <div>
                    <a
                      href="https://energia.conahcyt.mx/sargazo/"
                      target="_blank"
                      aria-label="Ver componente en mapa interactivo del capítulo Arribo del sargazo pelágico a las costas mexicanas. Abre nueva pestaña."
                      class="boton boton-primario boton-chico m-t-3"
                    >
                      Ver componente
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="columna-4 columna-8-mov">
              <div class="tarjeta">
                <picture class="fondo-imagen">
                  <source
                    :srcset="`${cdnArchivos}/inicio/cambioclimatico.webp`"
                    type="image/webp"
                  />
                  <img
                    loading="lazy"
                    class="fondo-imagen"
                    :src="`${cdnArchivos}/inicio/cambioclimatico.png`"
                    alt=""
                  />
                </picture>
                <div class="contenido-html con-imagen">
                  <div>
                    <p class="notas">ENI Energía y cambio climático</p>
                    <p class="parrafo-texto-desplazado">Estilo de portada</p>
                    <p>Portada de ENI <i>Energía y cambio climático</i></p>
                  </div>
                  <div>
                    <a
                      href="https://energia.conahcyt.mx/"
                      target="_blank"
                      aria-label="Ver componente en portada de ENI Energía y cambio climático. Abre nueva pestaña."
                      class="boton boton-primario boton-chico m-t-3"
                    >
                      Ver componente
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="texto-centrado">
            Hecho con
            <span>
              <span class="a11y-solo-lectura">amor</span>
              <span
                class="icono-corazon"
                style="
                  color: #793bff;
                  font-size: 24px;
                  transform: translateY(5px);
                  margin-right: 2px;
                "
                aria-hidden="true"
              ></span>
              <span
                class="icono-corazon"
                style="
                  color: #36c216;
                  font-size: 24px;
                  transform: translateY(5px);
                "
                aria-hidden="true"
              ></span>
            </span>
          </p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VistaInicio",
  computed: {
    ...mapGetters(["cdnArchivos"]),
  },
  created() {
    this.calHeight();
  },
  mounted() {
    window.addEventListener("resize", this.calHeight);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calHeight);
  },

  methods: {
    calHeight() {
      const innerheight = window.innerHeight / 100;
      document
        .querySelector(":root")
        .style.setProperty("--vh", `${innerheight.toString()}px`);
    },
  },
  watch: {
    filtro_seleccionado(nv) {
      // console.log(nv, this.listado_herramientas_visible);
      if (nv == "Todas_las_herramientas") {
        this.listado_herramientas_visible = this.listado_herramientas_completo;
      } else {
        this.listado_herramientas_visible =
          this.listado_herramientas_completo.filter(
            (d) => d.categoria.split(" ").join("_") == nv
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color-texto-landing-sisdai {
  color: var(--tipografia-color-2);
}
.contenedor-portada {
  #v-escritorio {
    display: none;
    @media (min-width: map-get($breakpoints, "esc")) {
      display: block;
    }
  }
  #v-movil {
    display: block;
    @media (min-width: map-get($breakpoints, "esc")) {
      display: none;
    }
  }
}
.tarjeta {
  .contenido-html {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.con-imagen {
      height: calc(100% - 240px);
    }
  }
}

section#que-se-puede-hacer {
  div.flex.flex-contenido-separado {
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
    .columna-8 {
      align-content: center;
      img {
        width: 100%;
      }
    }
    @media (min-width: 769px) {
      .oculta-escritorio {
        display: none;
      }
    }
    @media (max-width: 769px) {
      .oculta-movil {
        display: none;
      }
    }
  }
}
section#aplicaciones {
  .flex-contenido-separado {
    .columna-8-mov {
      margin-bottom: 24px;
    }
  }
}
</style>
