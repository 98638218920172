<script setup>
/*global globalThis*/
import { onUpdated, onMounted, computed, watch, ref, nextTick } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router/composables";

const ruta = useRoute();
const router = useRouter()
const infoDespliegue = {
  versionProyecto: process.env.PACKAGE_VERSION,
  actualizacionProyecto: process.env.DATE_DEPLOY,
  entornoProyecto: process.env.ENV_DEPLOY,
};

const sisdaiMenuAccesibilidad = ref();
const menuAccesiblidadAbierto = ref();
const entradaInicial = ref(true);

onMounted(async () => {
  // Para obtener el estado del botón de accesiblidad
  menuAccesiblidadAbierto.value = computed(
    () =>
      sisdaiMenuAccesibilidad.value?._setupState?.menuAccesibilidadEstaAbierto
        .value
  );
  await router.onReady(()=>  {ruta.path=="/" ? entradaInicial.value=false : null})

  agregandoAriaHidden();
});

onUpdated(agregandoAriaHidden);

function agregandoAriaHidden() {
  // ocultamos la lectura de los íconos que son enlaces externos
  document.querySelectorAll("span.icono-enlace-externo").forEach((el) => {
    el.setAttribute("aria-hidden", "true");
  });
}

const vistaSimplificada = computed(
  () => store.state.accesibilidad.vista_simplificada
);
const tipografiaAccesible = computed(
  () => store.state.accesibilidad.tipografia_accesible
);
const enlacesSubrayados = computed(
  () => store.state.accesibilidad.enlaces_subrayados
);

watch(vistaSimplificada, () => {
  const matomoPaq = globalThis._paq || { push() {} };
  const eventName = vistaSimplificada.value ? "activacion" : "desactivacion";
  matomoPaq.push([
    "trackEvent",
    "Accesibilidad",
    "Vista simplificada",
    eventName,
  ]);
});

watch(tipografiaAccesible, () => {
  const matomoPaq = globalThis._paq || { push() {} };
  const eventName = tipografiaAccesible.value ? "activacion" : "desactivacion";
  matomoPaq.push([
    "trackEvent",
    "Accesibilidad",
    "Tipografía accesible",
    eventName,
  ]);
});

watch(enlacesSubrayados, () => {
  const matomoPaq = globalThis._paq || { push() {} };
  const eventName = enlacesSubrayados.value ? "activacion" : "desactivacion";
  matomoPaq.push([
    "trackEvent",
    "Accesibilidad",
    "Enlaces subrayados",
    eventName,
  ]);
});

watch(menuAccesiblidadAbierto, () => {
  const matomoPaq = globalThis._paq || { push() {} };
  const eventName = menuAccesiblidadAbierto.value ? "abriendo" : "cerrando";
  matomoPaq.push([
    "trackEvent",
    "Accesibilidad",
    "Abrir y colapsar menú",
    eventName,
  ]);
});

watch(
  () => ruta.path,
  () => {
    if (!entradaInicial.value) {
      if (ruta.path) {
        nextTick(() => {
          moviendoFocoNavegacion(ruta.path);
        });
      }
    } else {
      entradaInicial.value= false
    }
  }
);

/**
 * Mueve el foco de navegación al contenido del documento o 
 * todo el contenido, dependiendo si la ruta tiene más de 
 * una carpeta anidada o no
 */
function moviendoFocoNavegacion(path) {
  let carpetas = path.split("/").filter((d) => d != "");

  if (carpetas.length >= 1) {
    const elemento_receptor = document.querySelector("#contenido-documento");
    if (elemento_receptor) {
      elemento_receptor.tabIndex = "-1";
      elemento_receptor.focus({preventScroll:true});
    }
  } else {
    const elemento_receptor = document.querySelector("#contenido-todo");
    if (elemento_receptor) {
      elemento_receptor.tabIndex = "-1";
      elemento_receptor.focus({preventScroll:true});
    }
  }
}
</script>

<template>
  <div id="app" :class="store.getters['accesibilidad/clasesAccesibles']">
    <a href="#principal" class="ir-contenido-principal"
      >Ir a contenido principal</a
    >

    <SisdaiNavegacionGobMx />
    <SisdaiNavegacionPrincipal :navInformacion="`Sección: <b>${$route.name}</b>`">
      <ul class="nav-menu">
        <li>
          <router-link class="nav-hipervinculo" to="/" exact
            >Inicio</router-link
          >
        </li>
        <li>
          <router-link class="nav-hipervinculo" to="/disenio/"
            >Diseño</router-link
          >
        </li>
        <li>
          <router-link class="nav-hipervinculo" to="/accesibilidad/"
            >Accesibilidad</router-link
          >
        </li>
        <li>
          <router-link class="nav-hipervinculo" to="/desarrollo/"
            >Desarrollo</router-link
          >
        </li>
        <li>
          <router-link class="nav-hipervinculo" to="/acceso-abierto"
            >Acceso Abierto</router-link
          >
        </li>
        <li>
          <router-link class="nav-hipervinculo" to="/acerca-de"
            >Acerca de</router-link
          >
        </li>
      </ul>
    </SisdaiNavegacionPrincipal>

    <router-view id="contenido-todo" />
    <SisdaiMenuAccesibilidad
      ref="sisdaiMenuAccesibilidad"
      :objetoStore="store"
    />

    <SisdaiBotonFlotante
      :enlaces="[
        {
          clasesCss: 'eni',
          contenido: 'Ir a ENI',
          enlace: 'https://eni.conahcyt.mx/',
        },
        {
          clasesCss: 'gema',
          contenido: 'Ir a GEMA',
          enlace: 'https://gema.conahcyt.mx/',
        },
      ]"
    />

    <SisdaiInfoDeDespliegue
      :entornoProyecto="infoDespliegue.entornoProyecto"
      :versionProyecto="infoDespliegue.versionProyecto"
      :actualizacionProyecto="infoDespliegue.actualizacionProyecto"
    />

    <SisdaiPiePaginaConahcyt />

    <SisdaiPiePaginaGobMx />
  </div>
</template>

<style lang="scss">
@import "node_modules/sisdai-css/src/eni.scss";

#app {
  .fondo-claro-1 {
    background: #eae9f0;
  }
  .fondo-claro-2 {
    background: #ffffff;
  }
  .fondo-claro-3 {
    background: #f6f6f6;
  }
  .indice-de-contenido {
    position: sticky;
    width: 100%;
    top: 100px;

    @media (min-width: 940px) {
      &#indice-contenidos-escritorio {
        display: inherit;
      }
      &#indice-contenidos-movil {
        display: none;
      }
    }
    @media (max-width: 940px) {
      &#indice-contenidos-escritorio {
        display: none;
      }
      &#indice-contenidos-movil {
        position: relative;
        display: inherit;
        top: 0;
      }
    }
  }
}
// estilos para el índice de contenido
.indice-de-contenido-sisdai {
  position: sticky;
  top: 80px;
  padding: 28px 0;
}
@media (min-width: 768px) {
  .contenedor-indice-y-contenido {
    flex-direction: row-reverse;
  }
}
@media (min-width: 768px) {
  .menu-fondo {
    background: var(--menu-lateral-fondo);
    .menu-lateral-contenedor {
        background: transparent;
    }  
  }
}
.contenedor-boton-flotante .boton-flotante-contenido {
  border-color: transparent;
}
.boton-flotante-contenido .eni,
.boton-flotante-contenido .eni:hover,
.boton-flotante-contenido .eni:focus {
  background: #00aaf6;
  color: #000 !important;
}

.boton-flotante-contenido .gema,
.boton-flotante-contenido .gema:hover,
.boton-flotante-contenido .gema:focus {
  background: #ff5100;
  color: #000 !important;
}

ol li::marker {
  font-family: var(--tipografia-familia);
}

ol.referencias-de-imagen li::marker {
  font-weight: 600;
  font-size: 1rem;
  color: var(--tipografia-color-2);
}
ol.referencias-de-imagen li {
  ul li::marker {
    color: #141414;
  }
  ul li span {
    font-weight: 500;
  }
}
dl:not(.info-entorno) dd {
  color: var(--tipografia-color);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
  display: block;
}
.texto-seccion-intro-titulo {
  color: var(--tipografia-color-2);
  font-size: 1.5rem;
  font-weight: 500;
}
.texto-seccion-intro-cuerpo {
  color: var(--tipografia-color-2);
}
</style>
