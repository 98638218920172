import Vue from "vue";
import VueRouter from "vue-router";
import VistaInicio from "@/views/VistaInicio.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: VistaInicio,
  },
  {
    path: "/acceso-abierto",
    name: "Acceso Abierto",
    component: () => import("@/views/VistaAccesoAbierto.vue"),
  },
  {
    path: "/acerca-de",
    name: "Acerca de",
    component: () => import("../views/VistaAcercaDe.vue"),
  },
  // Desarrollo
  {
    path: "/desarrollo",
    component: () => import("../views/VistaDesarrollo.vue"),
    children: [
      {
        path: "/",
        name: "Comienza",
        component: () => import("../views/desarrollo/DesarrolloComienza.vue"),
      },
      {
        path: "bibliotecas-sisdai",
        name: "Bibliotecas Sisdai",
        component: () =>
            import("../views/desarrollo/DesarrolloBibliotecasSisdai.vue"),
      },
    ],
  },
  // Disenio
  {
    path: "/disenio",
    component: () => import("../views/VistaDisenio.vue"),
    children: [
      {
        path: "/",
        name: "Introducción diseño",
        component: () => import("../views/disenio/DisenioIntroduccion.vue"),
      },
      {
        path: "reticula",
        name: "Retícula",
        component: () => import("../views/disenio/DisenioReticula.vue"),
      },
      {
        path: "espaciado",
        name: "Espaciado",
        component: () => import("../views/disenio/DisenioEspaciado.vue"),
      },
      {
        path: "tipografia",
        name: "Tipografía",
        component: () => import("../views/disenio/DisenioTipografia.vue"),
      },
      {
        path: "pictogramas",
        name: "Pictogramas",
        component: () => import("../views/disenio/DisenioPictogramas.vue"),
      },
      {
        path: "portadas-eni",
        name: "Portadas ENI",
        component: () => import("../views/disenio/DisenioPortadasEni.vue"),
      },
      {
        path: "portadas-capitulos",
        name: "Portadas Capítulos",
        component: () => import("../views/disenio/DisenioPortadasCapitulos.vue"),
      },
      {
        path: "creditos",
        name: "Créditos",
        component: () => import("../views/disenio/DisenioCreditos.vue"),
      },
    ],
  },
  {
    path: "/accesibilidad",
    children: [
      {
        path: "/",
        name: "Declaración de Accesibilidad",
        component: () =>
            import("@/views/accesibilidad/AccesibilidadDeclaracion.vue"),
      },
      {
        path: "cambio-fuente",
        name: "Cambio de fuente",
        component: () => import("../views/accesibilidad/AccesibilidadCambioFuente.vue"),
      },
      {
        path: "enlaces-subrayados",
        name: "Enlaces Subrayados",
        component: () => import("../views/accesibilidad/AccesibilidadVistaEnlacesSubrayados"),
      },
      {
        path: "vista-simplificada",
        name: "Vista Simplificada",
        component: () => import("../views/accesibilidad/AccesibilidadVistaSimplificada"),
      },
      {
        path: "menu-accesibilidad",
        name: "Menú accesibilidad",
        component: () => import("../views/accesibilidad/AccesibilidadVistaMenuAccesibilidad"),
      },
    ],
    component: () => import("@/views/VistaAccesibilidad.vue"),
  },
  {
    path: "**",
    name: "Página no encontrada",
    component: () => import("@/views/404Err.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: {
          x: 0,
          y: 80,
        },
      };
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

// Muestra el título de la página dependiendo de las vistas
router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} | ${to.name}`
  next()
})

export default router;
